<template>
  <div>
    <v-container>
      <v-row class="mt-6">
        <v-col class="text-center text-h3 font-weight-bold"> ログイン </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-form v-model="valid" @submit.prevent="onsubmit">
        <v-row>
          <v-col cols="2"></v-col>
          <v-col>
            <v-text-field
              v-model="username"
              label="店舗名"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
        <v-col cols="2"></v-col>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col>
            <v-text-field
              v-model="password"
              label="パスワード"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
        <v-col align="center" class="mt-6">
          <v-btn color="secondary" elevation="8" x-large type="submit"
            >ログイン</v-btn
          >
        </v-col>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { CSRF_TOKEN } from "../common/csrf_token.js";
import router from "../store/index";

export default {
  name: "LoginView",
  data() {
    return {
      username: this.$store.state.username,
      password: this.$store.state.password,
    };
  },
  methods: {
    onsubmit() {
      let endpoint = this.$store.state.url + "auth/jwt/create/";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          // "Access-Control-Allow-Origin": "http://118.27.10.81",
          // "Access-Control-Allow-Origin": "http://127.0.0.1:8080",
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        }),
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else {
            console.log("401");
            router.push({
              name: "login",
            });
          }
        })
        .then((data) => {
          this.$store.commit("setToken", data);
          this.$store.commit("setUserName", this.username, this.password);
          this.$router.push({
            name: "home",
          });
        });
    },
  },
};
</script>
