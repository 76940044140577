import Vue from "vue";
import Vuex from "vuex";
import { CSRF_TOKEN } from "../common/csrf_token.js";
import router from "@/router/index.js";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    url: "https://yuugenhirai.top/api/",
    // url: "http://localhost:8000/api/",
    // url: "http://127.0.0.1:8000/api/",
    username: "",
    password: "",
    access_token: "",
    refresh_token: "",
    account_titles: [],
    descriptions: {},
  },
  getters: {},
  mutations: {
    setToken(state, data) {
      state.access_token = data["access"];
      state.refresh_token = data["refresh"];
    },
    setAccessToken(state) {
      let endpoint = state.url + "auth/jwt/refresh/";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          refresh: state.refresh_token,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 404) {
            return null;
          } else if (response.status == 401) {
            router.push({
              name: "login",
            });
          } else {
            return response.json();
          }
        })
        .then((data) => {
          state.access_token = data["access"];
        });
    },
    setUserName(state, username, password) {
      state.username = username;
      state.password = password;
    },
    setAccounttitlesDescriptions(state, data) {
      state.account_titles = data[0];
      state.descriptions = data[1];
    },
    logout(state) {
      state.access_token = "";
      state.refresh_token = "";
      state.username = "";
      state.password = "";
      router.push({
        name: "login",
      });
    },
  },
  actions: {},
  modules: {},
});
