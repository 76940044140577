<template>
  <div>
    <main-header />

    <v-row class="justify-center">
      <v-container>
        <v-col>
          <v-col cols="6">
            <Datepicker
              v-model="date"
              :language="ja"
              :format="DatePickerFormat"
              :required="true"
              :minimum-view="'month'"
              @closed="pickerClosedChange"
            ></Datepicker>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="username"
              :items="usernames"
              item-text="username"
              item-value=""
              label="店名"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-col>
        <v-btn @click="cashBookDownLoad">現金出納帳</v-btn>
      </v-container>
    </v-row>

    <v-row>
      <v-btn @click="salaryCalculation">給料計算</v-btn>
    </v-row>
    <v-row>
      <v-btn @click="salaryItemDownLoad">明細ダウンロード</v-btn>
    </v-row>
  </div>
</template>

<script>
import { CSRF_TOKEN } from "../common/csrf_token.js";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "DownloadView",
  data() {
    return {
      DatePickerFormat: "yyyy年MM月",
      ja: ja,
      usernames: [],
      date: new Date(),
      salaryDate: new Date(),
      username: "",
      year: "",
      month: "",
    };
  },
  methods: {
    salaryCalculation() {
      let endpoint = this.$store.state.url + "salary_calculation";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          username: this.username,
          date: moment(this.salaryDate).format("YYYY-MM"),
        }),
      })
        .then((response) => {
          if (response.status == 201) {
            return response.json();
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.salaryCalculation();
          } else {
            return null;
          }
        })
        .then((data) => {
          console.log(data);
        });
    },
    salaryItemDownLoad() {
      let endpoint = this.$store.state.url + "salary_item";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          username: this.username,
          date: this.date,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.salaryItemDownLoad();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.blob();
          }
        })
        .then((data) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = this.date + ".xlsx";
          link.click();
        });
    },
    cashBookDownLoad() {
      let endpoint = this.$store.state.url + "cash_book";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          username: this.username,
          date: this.date,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.cashBookDownLoad();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.blob();
          }
        })
        .then((data) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = this.date + ".xlsx";
          link.click();
        });
    },
    pickerClosedChange() {
      this.date = moment(this.date).format("YYYY-MM");
    },
    getUserNames() {
      let endpoint = this.$store.state.url + "accounts/users";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getUserNames();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          for (let i in data) {
            this.usernames.push(data[i].username);
          }
        });
    },
    getToday() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      this.date = year + "-" + month;
    },
  },
  components: {
    Datepicker,
    MainHeader,
  },
  created() {
    this.getUserNames();
    this.getToday();
  },
};
</script>
