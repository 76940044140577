import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SettingsView from "../views/Settings.vue";
import RecordListView from "../views/RecordList.vue";
import DownloadView from "../views/DownLoad.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    // meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
  {
    path: "/record_list",
    name: "record_list",
    component: RecordListView,
  },
  {
    path: "/download",
    name: "download",
    component: DownloadView,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && store.state.access_token == "") {
    next({ name: "login" });
  } else next();
});
export default router;
