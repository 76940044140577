<template>
  <div>
    <main-header />
    <v-snackbar v-model="snackbar" :timeout="4000" :top="true">
      登録しました
    </v-snackbar>
    <v-form v-model="valid" @submit.prevent="onsubmit">
      <v-container>
        <v-col>
          <v-col>
            <Datepicker
              v-model="date"
              :language="ja"
              :format="DatePickerFormat"
              :required="true"
              :calendar-button="true"
              ref="picker"
              @closed="pickerClosedChange"
            ></Datepicker>
            <button type="button" @click="doOpen()">open</button>
          </v-col>
          <v-col cols="8" class="mx-auto">
            <v-combobox
              v-model="account_title"
              :items="this.$store.state.account_titles"
              item-text="state"
              item-value="abbr"
              label="科目"
              persistent-hint
              return-object
              single-line
            ></v-combobox>
          </v-col>
          <v-col cols="8" class="mx-auto">
            <v-combobox
              v-model="description"
              :items="this.$store.state.descriptions[account_title]"
              item-text="state"
              item-value="abbr"
              label="詳細"
              persistent-hint
              return-object
              single-line
            ></v-combobox>
          </v-col>
          <v-col cols="8" class="mx-auto">
            <v-text-field
              type="tel"
              v-model="money_received"
              label="入金"
            ></v-text-field>
          </v-col>
          <v-col cols="8" class="mx-auto">
            <v-text-field
              type="tel"
              v-model="payment"
              label="出金"
            ></v-text-field>
          </v-col>
        </v-col>
      </v-container>
      <v-container>
        <v-row class="justify-center">
          <v-btn x-large elevation="2" color="primary" type="submit"
            >登録する</v-btn
          >
        </v-row>
      </v-container>
    </v-form>
    <v-container class="mt-4">
      <v-row class="justify-center">
        <v-btn
          class="ma-2"
          outlined
          color="indigo"
          x-large
          elevation="2"
          type="submit"
          @click="dataClear"
          >クリア</v-btn
        ></v-row
      >
    </v-container>
    <v-footer dark padless>
      <v-card-text class="white--text">
        残高：<strong>{{ balance }} 円</strong></v-card-text
      >
    </v-footer>
  </div>
</template>

<script>
import { CSRF_TOKEN } from "../common/csrf_token.js";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "HomeView",
  data() {
    return {
      DatePickerFormat: "yyyy年MM月dd日",
      ja: ja,
      valid: false,
      date: new Date(),
      account_title: "",
      description: "",
      money_received: "",
      payment: "",
      balance: "",
      snackbar: false,
      snackbar_2: true,
      // nameRules: [(v) => !!v || "お名前を入力して下さい。"],
      // telRules: [(v) => !!v || "電話番号を入力して下さい。"],
      // emailRules: [
      //   (v) => !!v || "メールアドレスを入力して下さい。",
      //   (v) => /.+@.+/.test(v) || "E-mail must be valid",
      // ],
    };
  },
  methods: {
    onsubmit() {
      if (this.account_title == "") {
        return;
      }
      if (this.money_received == "" && this.payment == "") {
        return;
      }
      let endpoint = this.$store.state.url + "record_registration";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          date: this.date,
          account_title: this.account_title,
          description: this.description,
          money_received: this.money_received,
          payment: this.payment,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            return "401";
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data == "401") {
            this.$store.commit("setAccessToken");
            this.onsubmit();
          } else {
            this.account_title = "";
            this.description = "";
            this.money_received = "";
            this.payment = "";
            this.snackbar = true;
          }
        });
    },
    pickerClosedChange() {
      this.date = moment(this.date).format("YYYY-MM-DD");
    },
    getData() {
      let endpoint = this.$store.state.url + "account_title";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getData();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.$store.commit("setAccounttitlesDescriptions", data);
        });
    },
    getBalance() {
      let endpoint = this.$store.state.url + "balance";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getBalance();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.balance = data;
        });
    },
    getToday() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      this.date = year + "-" + month + "-" + day;
    },
    dataClear() {
      this.account_title = "";
      this.description = "";
      this.money_received = "";
      this.payment = "";
    },
    doOpen: function () {
      if (!this.$refs.picker.isOpen) {
        this.$refs.picker.$el.querySelector("input").focus();
        this.$refs.picker.showCalendar();
      }
    },
    doClose: function () {
      if (this.$refs.picker.isOpen) {
        this.$refs.picker.close(true);
      }
    },
  },
  components: {
    Datepicker,
    MainHeader,
  },
  created() {
    this.getData();
    this.getToday();
    this.getBalance();
  },
};
</script>
