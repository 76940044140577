<template>
  <div>
    <main-header />

    <v-data-table
      :headers="headers1"
      :items="account_titles"
      :mobile-breakpoint="0"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> 科目編集 </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog1" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="isAccountTitleOnly = true"
                v-bind="attrs"
                v-on="on"
              >
                新規登録
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">「科目」 編集・登録</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.account_title"
                        item-text="state"
                        item-value="abbr"
                        label="科目"
                        persistent-hint
                        return-object
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  キャンセル
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> 登録 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editAccountTitle(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item, 'account_title')">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-data-table
      :headers="headers2"
      :items="descriptions"
      :mobile-breakpoint="0"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> 詳細編集 </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog2" max-width="500px">
            <template v-slot:activator="{ on, attrs2 }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs2"
                v-on="on"
              >
                新規登録
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">「科目・詳細」 編集・登録</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="editedItem.account_title"
                        :items="account_title_list"
                        item-text="state"
                        item-value="abbr"
                        label="科目"
                        persistent-hint
                        return-object
                        single-line
                      ></v-combobox>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.description"
                        item-text="state"
                        item-value="abbr"
                        label="詳細"
                        persistent-hint
                        return-object
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  キャンセル
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> 登録 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editDescription(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item, 'description')">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { CSRF_TOKEN } from "../common/csrf_token.js";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "SettingsView",
  data() {
    return {
      // プルダウンメニュー用
      account_title_list: [],
      // データテーブル用
      account_titles: [],
      descriptions: [],
      old_account_title: "",
      old_description: "",
      dialog1: false,
      dialog2: false,
      dialogDelete: false,
      isAccountTitleOnly: false,
      editedIndex: -1,
      editedItem: {
        account_title: "",
        description: "",
      },
      defaultItem: {
        account_title: "",
        description: "",
      },
    };
  },
  computed: {
    headers1() {
      return [
        {
          text: "科目",
          value: "account_title",
        },
        // { text: "詳細", value: "description" },
        { text: "編集", value: "actions", sortable: false },
      ];
    },
    headers2() {
      return [
        {
          text: "科目",
          value: "account_title",
        },
        { text: "詳細", value: "description" },
        { text: "編集", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    editAccountTitle(item) {
      this.editedIndex = this.account_titles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.old_account_title = item.account_title;
      this.editedItem["description"] = "account_title_only";
      this.isAccountTitleOnly = true;
      this.dialog1 = true;
    },
    editDescription(item) {
      this.editedIndex = this.descriptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.old_account_title = item.account_title;
      this.old_description = item.description;
      this.dialog2 = true;
    },
    deleteItem(item, target) {
      let targetList = [];
      let body = "";
      if (target === "account_title") {
        targetList = this.account_titles;
        body = JSON.stringify({
          account_title: item.account_title,
          description: "account_title_only",
        });
      } else {
        targetList = this.descriptions;
        body = JSON.stringify({
          account_title: item.account_title,
          description: item.description,
        });
      }
      this.editedIndex = targetList.indexOf(item);
      let endpoint = this.$store.state.url + "account_title_delete";

      fetch(endpoint, {
        method: "DELETE",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: body,
      }).then((response) => {
        if (response.status == 204) {
          return "";
        } else if (response.status == 401) {
          this.$store.commit("setAccessToken");
          this.deleteItem();
        } else if (response.status == 404) {
          return null;
        } else {
          return "";
        }
      });
      targetList.splice(this.editedIndex, 1);
      //   this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.record_list.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog1 = false;
      this.dialog2 = false;
      this.isAccountTitleOnly = false;
      this.old_account_title = "";
      this.old_description = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getToday();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let endpoint = "";
      //   新規登録の時と編集の時を分けている
      if (this.editedIndex == -1) {
        endpoint = this.$store.state.url + "account_title_registration";
      } else {
        // pkを取るのがめんどくさいためPOSTで処理
        endpoint = this.$store.state.url + "account_title_update";
      }

      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          old_account_title: this.old_account_title,
          old_description: this.old_description,
          account_title: this.editedItem["account_title"],
          description: this.editedItem["description"],
        }),
      })
        .then((response) => {
          if (response.status == 201) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.save();
          } else if (response.status == 404) {
            return null;
          } else {
            if (this.editedIndex > -1) {
              if (this.isAccountTitleOnly) {
                Object.assign(
                  this.account_titles[this.editedIndex],
                  this.editedItem
                );
              } else {
                Object.assign(
                  this.descriptions[this.editedIndex],
                  this.editedItem
                );
              }
            } else {
              if (this.isAccountTitleOnly) {
                console.log(this.editedItem);
                this.account_titles.unshift(this.editedItem);
              } else {
                this.descriptions.unshift(this.editedItem);
              }
            }
          }
        })
        .then(() => {
          this.close();
        });
    },
    // 科目と詳細を取得
    getAccountTitleData() {
      let endpoint = this.$store.state.url + "account_title";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getAccountTitleData();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.$store.commit("setAccounttitlesDescriptions", data);
        })
        .then(() => {
          this.account_title_list = this.$store.state.account_titles;
          // データテーブルの形式に合わせるための処理
          for (let i in this.$store.state.account_titles) {
            this.account_titles.push({
              account_title: this.$store.state.account_titles[i],
            });
          }
          const _descriptions = this.$store.state.descriptions;
          for (let key in _descriptions) {
            for (let _key in _descriptions[key]) {
              this.descriptions.push({
                account_title: key,
                description: _descriptions[key][_key],
              });
            }
          }
        });
    },
  },
  components: {
    MainHeader,
  },
  created() {
    this.getAccountTitleData();
  },
};
</script>
