<template>
  <v-toolbar :dark="true">
    <v-btn icon :to="{ name: 'home' }"> TOP </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon :to="{ name: 'record_list' }">
      <v-icon>mdi-view-list</v-icon>
    </v-btn>
    <v-btn icon :to="{ name: 'settings' }">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn icon :to="{ name: 'download' }">
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn icon @click="logout">
      <v-icon> mdi-logout</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      title: "",
    };
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
  },
  created() {
    this.title = this.$store.state.username;
  },
};
</script>
