<template>
  <v-container class="pa-0">
    <main-header />

    <v-snackbar v-model="snackbar" :timeout="4000" :top="true">
      削除できませんでした
    </v-snackbar>

    <v-data-table
      :headers="headers"
      :items="record_list"
      :mobile-breakpoint="0"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> 一覧 </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-form>
            <Datepicker
              v-model="date"
              :language="ja"
              :format="DatePickerFormat"
              :required="true"
              :minimum-view="'month'"
              @closed="pickerClosedChange"
            ></Datepicker
          ></v-form>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                新規登録
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <Datepicker
                        v-model="editedItem.date"
                        :language="ja"
                        :format="dialogDatePickerFormat"
                        :required="true"
                        @closed="dialogPickerClosedChange"
                      ></Datepicker>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="editedItem.account_title"
                        :items="account_titles"
                        item-text="state"
                        item-value="abbr"
                        label="科目"
                        persistent-hint
                        return-object
                        single-line
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.money_received"
                        label="入金"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.payment"
                        label="出金"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="editedItem.description"
                        :items="descriptions[editedItem.account_title]"
                        item-text="state"
                        item-value="abbr"
                        label="詳細"
                        persistent-hint
                        return-object
                        single-line
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  キャンセル
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> 登録 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">本当に削除しますか?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm(item)"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { CSRF_TOKEN } from "../common/csrf_token.js";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "record_list",
  data() {
    return {
      DatePickerFormat: "yyyy年MM月",
      dialogDatePickerFormat: "yyyy年MM月dd日",
      ja: ja,
      valid: false,
      date: "",
      account_titles: [],
      //   科目に紐づけているので辞書になっている
      descriptions: {},
      record_list: [],
      delItem: {},
      snackbar: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        date: "",
        account_title: "",
        money_received: 0,
        payment: 0,
        description: "",
      },
      defaultItem: {
        date: "",
        account_title: "",
        money_received: 0,
        payment: 0,
        description: "",
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "日付",
          align: "start",
          value: "date",
        },
        {
          text: "科目",
          value: "account_title",
        },
        { text: "入金", value: "money_received" },
        { text: "出金", value: "payment" },
        { text: "詳細", value: "description" },
        { text: "編集", value: "actions", sortable: false },
      ];
    },
    // watch: {
    //   dialog(val) {
    //     val || this.close();
    //   },
    //   dialogDelete(val) {
    //     val || this.closeDelete();
    //   },
    // },
  },
  methods: {
    // djangoで今月のrecordを取得するようにしている
    getRecord() {
      let endpoint = this.$store.state.url + "record";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getRecord();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.record_list = data;
          this.getAccountTitleData();
        });
    },
    editItem(item) {
      this.editedIndex = this.record_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.delItem = Object.assign({}, item);
      this.editedIndex = this.record_list.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let endpoint =
        this.$store.state.url +
        "record_delete/" +
        this.record_list[this.editedIndex]["id"];
      fetch(endpoint, {
        method: "DELETE",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.snackbar = true;
            this.$store.commit("setAccessToken");
            this.getRecord();
          } else if (response.status == 404) {
            return null;
          } else {
            return "";
          }
        })
        .then(() => {
          console.log("delete");
        });
      this.record_list.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getToday();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let endpoint = "";
      let method = "";
      //   新規登録の時と編集の時を分けている
      if (this.editedIndex == -1) {
        endpoint = this.$store.state.url + "record_registration";
        method = "POST";
      } else {
        endpoint =
          this.$store.state.url +
          "record_update/" +
          this.record_list[this.editedIndex]["id"];
        method = "PUT";
      }

      this.editedItem["date"] = moment(this.editedItem["date"]).format(
        "YYYY-MM-DD"
      );

      fetch(endpoint, {
        method: method,
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          date: this.editedItem["date"],
          account_title: this.editedItem["account_title"],
          description: this.editedItem["description"],
          money_received: this.editedItem["money_received"],
          payment: this.editedItem["payment"],
        }),
      })
        .then((response) => {
          if (response.status == 201) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.save();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (this.editedIndex > -1) {
            Object.assign(this.record_list[this.editedIndex], this.editedItem);
          } else {
            // PUTやDELETEの時IDが必要なので付与
            this.record_list.unshift(this.editedItem);
            this.record_list[0]["id"] = data["pk"];
          }
        })
        .then(() => {
          this.close();
        });
    },
    // 月ごとのデータを取得
    pickerClosedChange() {
      this.date = moment(this.date).format("YYYY-MM");
      let endpoint = this.$store.state.url + "record";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: JSON.stringify({
          date: this.date,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.pickerClosedChange();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.record_list = data;
        });
    },
    dialogPickerClosedChange() {
      this.date = moment(this.date).format("YYYY-MM-DD");
    },
    // カレンダーの初期値を今日の日付にする
    getToday() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      this.editedItem.date = year + "-" + month + "-" + day;
      this.date = year + "-" + month;
    },
    // 科目と詳細を取得
    getAccountTitleData() {
      let endpoint = this.$store.state.url + "account_title";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.access_token,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getAccountTitleData();
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.$store.commit("setAccounttitlesDescriptions", data);
        });
    },
  },
  components: {
    Datepicker,
    MainHeader,
  },
  created() {
    this.getRecord();
    this.getToday();
    this.account_titles = this.$store.state.account_titles;
    this.descriptions = this.$store.state.descriptions;
  },
};
</script>
